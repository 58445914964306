.Footer-container {
    position: relative;
}

.Footer-container > hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links {
    display: flex;
    gap: 3rem;
    color: var(--lightgray);
}

.telegram-icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.youtube-channel {
    cursor: pointer;
    transition: transform 0.2 ease-in-out;
    width: 55px;
}

.whatsapp-icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.email-icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.email-icon:hover,
.whatsapp-icon:hover,
.telegram-icon:hover,
.youtube-channel:hover {
    transform: scale(1.1);
    color: var(--orange);
}

.logo-f {
    width: 10rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.logo-f:hover {
    transform: scale(1.1);
}

.footer-blur-1 {
    bottom: 0;
    right: 17%;
    width: 37rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

.footer-blur-2 {
    bottom: 0;
    left: 17%;
    width: 37rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}
